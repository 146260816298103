
<template>
  <div class="container">
    <PurchaserPage v-if="BusinessRoleType.PURCHASER === business.roleType" />
    <Supplier v-else-if="BusinessRoleType.SUPPLIER === business.roleType" />
    <Designer v-else-if="BusinessRoleType.DESIGNER === business.roleType" />
    <Facilitator v-else-if="BusinessRoleType.FACILITATOR === business.roleType" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PurchaserPage from './Purchaser'
import Supplier from './Supplier'
import Designer from './Designer'
import Facilitator from './Facilitator'
import { getCurBusiness } from '@/util/utils'
import { BusinessRoleType } from '@/util/constant'

export default {
  computed: {
    BusinessRoleType() {
      return BusinessRoleType
    }
  },
  components: {
    PurchaserPage,
    Supplier,
    Designer,
    Facilitator
  },
  data() {
    return {
      business: {}
    }
  },
  created() {
    this.business = getCurBusiness()
    console.log(this.business)
  },
  methods: {
    ...mapGetters(['setUserInfo'])
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 12px 25px 16px;
  box-sizing: border-box;
  background: #fff;
  min-height: 820px;
}
</style>
