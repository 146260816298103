<template>
    <div class="view-file-box">
        <div class="view-file-box-item" v-for="i in fileList" :key="i.id">
            <!-- ext -->
            <img v-if="i.ext == 'doc' || i.ext == 'docx'" src="~@/assets/fileIcon/word.png" alt="" class="file-icon">
            <img src="~@/assets/fileIcon/excel.png" v-else-if="i.ext == 'xlsx' || i.ext == 'xls'" alt=""
                class="file-icon">
            <img src="~@/assets/fileIcon/jpg.png" v-else-if="i.ext == 'jpg'" alt="" class="file-icon">
            <img src="~@/assets/fileIcon/pdf.png" v-else-if="i.ext == 'pdf'" alt="" class="file-icon">
            <img src="~@/assets/fileIcon/png.png" v-else-if="i.ext == 'png'" alt="" class="file-icon">
            <img src="~@/assets/fileIcon/txt.png" v-else-if="i.ext == 'txt'" alt="" class="file-icon">
            <img src="~@/assets/fileIcon/zip.png" v-else-if="i.ext == 'zip' || i.ext == 'rar'" alt="" class="file-icon">
            <img src="~@/assets/fileIcon/default.png" v-else alt="" class="file-icon">
            <div class="file-info">
                <!-- filename -->
                <div class="file-name" :title="i.filename">{{ i.filename || '-' }}</div>
                <div class="left-side">

                    <!-- size 字节 -->
                    <span class="file-size">文件大小：{{ ((i.size || 0) / 1024).toFixed(2) }}kb</span>
                    <a-space v-if="fileUrl">
                        <a @click="handleDownload(i.url)">下载</a>
                    </a-space>
                    <a-space v-else>
                        <a @click="handleReUpload">重新上传</a>
                        <a @click="handleRemoveFile">删除</a>
                    </a-space>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { postAction } from '@/api/manage.js'
export default {
    props: {
        fileUrl: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            fileList: [

            ],

        }
    },
    watch: {
        fileUrl: {
            handler(newValue, oldValue) {
                console.log(newValue)
                if (newValue) {
                    this.handleGetFileList([newValue])
                }
            },
            immediate: true
        }
    },
    methods: {
        handleGetFileList(fileList) {
            postAction('/file/queryFileInfoList', fileList).then(res => {
                const { success, data } = res;
                if (success) {
                    this.fileList = []
                    if (data && data.length) {
                        this.fileList = data;
                    }
                }
            })
        },
        // 重新下载
        handleReUpload() {
            this.$emit('reUpload');
        },
        //删除
        handleRemoveFile() {
            this.$emit('remove')
        },
        handleDownload(url) {
            window.open(url)
        },
    },
}
</script>

<style lang="less" scoped>
.view-file-box {
    width: 100%;
    min-width: 346px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 25px 25px;

    &-item {
        // margin-top: 50px;
        min-width: 346px;
        height: 78px;
        border: 1px dotted #E8E9EB;
        display: flex;
        padding: 15px;
        box-sizing: border-box;
        justify-content: flex-start;
        align-items: center;
        background: #F7F8FA;
        border-radius: 4px;
        position: relative;

        .file-icon {
            width: 40px;
            height: 40px;
            margin-right: 8px;
        }

        .file-info {
            flex: 1 0 0;
            min-width: 0;
            height: 46px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            .left-side {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                a {
                    font-size: 12px;
                }
                .ant-space-item {
                    line-height: 19px!important;
                }
            }
            .file-name {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                height: 22px;
                width: 100%;
                letter-spacing: 0px;
                text-align: left;
                color: #131212;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .file-size {
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
                text-align: left;
                color: #8D8E99;
            }
        }

        .option-area {
            position: absolute;
            right: 8px;
            bottom: 8px;
        }
    }
}

.file-viewer-title {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #000;
    position: absolute;
    left: 0;
    top: -40px;
}
</style>