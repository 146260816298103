<template>
  <DrawerView :visible="visible" :width="1040" title="编辑计划采购信息" @confirm="handleOk" @cancel="handleCancel">
    <a-form-model ref="basicForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :colon="false">
      <a-row :gutter="26" class="basicInfo-form-item">
        <a-col :span="12">
          <a-form-model-item
            label="年包材需求总额"
          >
            <j-dictSelect
              v-model="form.packagingCostTotal"
              dictCode="1013"
              placeholder="请选择"
              style="width: 320px; height: 38px"
            /> </a-form-model-item
        ></a-col>
        <a-col :span="12">
          <a-form-model-item
            label="要货方式"
          >
            <j-dictSelect
              v-model="form.purchasingMethod"
              dictCode="1005"
              placeholder="请选择"
              style="width: 100%; max-width: 320px"
            />
          </a-form-model-item>
        </a-col>

        <a-col :span="12">
          <a-form-model-item
            label="供货区域"
          >
            <a-row style="width: 100%; max-width: 328px" :gutter="10">
              <a-col :span="12">
                <j-dictSelect v-model="form.providerArea" dictCode="1048" placeholder="请选择" style="width: 100%" />
              </a-col>
              <a-col :span="12" v-if="form.providerArea == '1048001'">
                <j-dictSelect
                  v-model="form.providerAreaRegion"
                  dictCode="1007"
                  placeholder="请选择"
                  style="width: 100%"
                />
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <template v-slot:btnArea>
      <a-button @click="handleCancel">取消</a-button>
      <a-button @click="handleOk('save')" :loading="loading" type="primary">确定</a-button>
    </template>
  </DrawerView>
</template>

<script>
import DrawerView from '@/components/plugins/drawerView.vue'
import { mapGetters } from 'vuex'
import { postQueryAction, postAction, getAction } from '@/api/manage'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import JMultiSelectTag from '@/components/plugins/JMultiSelectTag.vue'

export default {
  name: 'moreInfoForm',
  components: {
    DrawerView,
    JMultiSelectTag,
    JDictSelect,
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      visible: false,
      loading: false,
      form: {},
      roleType: 'BUSINESS',
      productTypeList: [],
      basicData: {},
      getPopupContainer: (node) => node.parentNode,
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleEdit() {
      const { tradeIdentity } = this.setUserInfo()
      this.tradeIdentity = tradeIdentity
      this.handleInitData()
      this.visible = true
    },
    handleInitData() {
      getAction('/material/category/queryByType', { categoryType: '1' }).then((res) => {
        const { data, success } = res
        success && data && (this.productTypeList = data)
      })
      const { roleType } = this.setUserInfo()
      this.roleType = roleType
      if (!this.setUserInfo().businessId) return
      postQueryAction('/business/queryByBusinessId', { businessId: this.setUserInfo().businessId }).then((res) => {
        const { success, data } = res
        if (success) {
          this.basicData = data
          const { supplierPurchasePlan } = data
          if (supplierPurchasePlan) {
            this.form = { ...supplierPurchasePlan }
          }
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.form = {}
      this.loading = false
      this.basicData = {}
    },
    handleOk() {
      const that = this
      this.$refs.basicForm.validate((valid) => {
        if (valid) {
          this.loading = true
          let formData = { ...this.basicData, ...this.form }
          postAction('/business/save/supplierPurchasePlan', {
            ...formData,
            userId: this.setUserInfo().userId,
            isSupplierPurchasePlan: true,
            businessId: this.setUserInfo().businessId,
          })
            .then((res) => {
              const { message, success } = res
              that.loading = false
              if (success) {
                // that.$message.success('信息修改成功')
                this.$emit('ok')
                that.handleCancel()
              } else {
                that.$message.warning(message)
              }
            })
            .catch((err) => {
              that.loading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
// ::v-deep .ant-form-item {
//   margin-bottom: 14px;
//   padding-right: 120px;
// }
::v-deep .ant-input {
  width: 320px;
  height: 38px;
  line-height: 38px;
}

::v-deep .ant-btn {
  width: 97px;
  height: 38px;
}

.text-area-box {
  width: 599px;
  position: relative;

  .counts {
    position: absolute;
    color: #00000073;
    right: 6px;
    bottom: 6px;
  }

  .option-btn {
    position: absolute;
    bottom: -5px;
    right: -68px;
  }
}

::v-deep .ant-select-selection {
  height: 38px;
}

::v-deep .ant-select-selection__rendered {
  line-height: 38px;
}
</style>
