<template>
  <DrawerView :visible="visible" :width="560" :title="basicData.id ? '编辑产品' : '添加产品'" @confirm="handleOk"
    @cancel="handleCancel">
    <a-form-model ref="basicForm" :model="basicData" :label-col="labelCol" :wrapper-col="wrapperCol" :colon="false">
      <a-form-model-item label="产品分类" prop="productCategory" :rules="{ required: true, message: '请选择产品分类' }">
        <j-dictSelect v-model="basicData.productCategory" dictCode="1507" placeholder="请选择"
          style="width: 100%; max-width: 320px" />
      </a-form-model-item>
      <a-form-model-item label="产品名称" prop="productName" :rules="{ required: true, message: '请输入产品名称' }">
        <a-input style="width: 320px;height: 38px;" placeholder="请输入" v-model="basicData.productName"></a-input>
      </a-form-model-item>
      <a-form-model-item label="产品图片" prop="productPicture" :rules="{ required: true, validator: uploadFileChange }">
        <uploadFile :number="1" :showUploadList="false" :uploadFileType="'image'" :defaultParams="{
          dir: 'picture',
        }" :needMemo="false" v-model="productPictList" />
      </a-form-model-item>

      <a-form-model-item label="显示状态">
        <a-radio-group :options="statusOptions" v-model="basicData.status" />
      </a-form-model-item>
    </a-form-model>
    <template v-slot:btnArea>
      <a-button @click="handleCancel">取消</a-button>
      <a-button @click="handleOk" :loading="loading" type="primary">确定</a-button>
    </template>
  </DrawerView>
</template>

<script>
import DrawerView from '@/components/plugins/drawerView.vue'
import { mapGetters } from 'vuex'
import { postQueryAction, postAction, getAction } from '@/api/manage'
import uploadFile from './uploadProductFile.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'

export default {
  name: 'moreInfoForm',
  components: {
    DrawerView,
    uploadFile,
    JDictSelect
  },
  data() {
    return {
      getPopupContainer: (node) => node.parentNode,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      visible: false,
      loading: false,
      basicData: {
        status: '1'
      },
      productTypeList: [],
      productPictList: [],
      statusOptions: [
        { label: '显示', value: '1' },
        { label: '下架', value: '0' }
      ],
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    // 自定义上传附件校验
    uploadFileChange(rule, value, callback) {
      if (this.productPictList.length === 0) {
        return callback('请上传产品图片')
      } else {
        return true
      }
    },
    handleEdit(item) {
      item && (this.basicData = { ...item })
      if (item.productPictures && item.productPictures != 'CCC') {
        let nameStr = item.productPictures.split('/images/')
        this.productPictList = [
          {
            url: item.productPictures,
            srcFileName: nameStr[nameStr.length - 1],
          },
        ]
        console.log(this.productPictList)
      }
      this.visible = true
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.basicData = {}
      this.productPictList = []
    },
    handleOk() {
      const that = this
      this.$refs.basicForm.validate(valid => {
        if (valid) {
          this.loading = true
          let url = this.basicData.id ? '/supplier/product/update' : '/supplier/product/add';
          postAction(url, {
            ...that.basicData,
            businessId: this.setUserInfo().businessId,
            productPictureList: this.productPictList.length ? [this.productPictList[0].url] : []
          })
            .then(res => {
              const { message, success } = res
              that.loading = false
              if (success) {
                that.$message.success('保存成功')
                this.$emit('ok')
                that.handleCancel()
              } else {
                that.$message.warning(message)
              }
            })
            .catch(err => {
              that.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
// ::v-deep .ant-form-item {
//   margin-bottom: 14px;
//   padding-right: 120px;
// }
::v-deep .ant-input {
  width: 320px;
  height: 38px;
  line-height: 38px;
}

::v-deep .ant-btn {
  width: 97px;
  height: 38px;
}

::v-deep .ant-input-number-input {
  height: 38px;
}
</style>