<template>
  <div class="Purchaser-container">
    <a-tabs v-model="activePart" @change="handleChangeTab">
      <a-tab-pane :key="0" tab="基础信息">
        <!-- 企业基本信息 -->
        <CompanyBasicInfo ref="refComp_0"  />
      </a-tab-pane>
      <a-tab-pane :key="2" tab="服务内容">
        <ServiceContent ref="refComp_2" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import CompanyBasicInfo from './companyBasicInfo.vue'
import ServiceContent from './serviceContent.vue'
import { mapGetters } from 'vuex'
import { IdType } from '@/util/constant'
  export default {
    computed: {
      IdType() {
        return IdType
      }
    },
    components: {
      CompanyBasicInfo,
      ServiceContent,
    },
    data() {
      return {
        activePart: 0,
        status: null,
        roleType: 'PERSON',
      }
    },
    methods: {
      ...mapGetters(['setUserInfo']),
      handleChangeStepInfo({ status }) {
        this.status = status;
        this.activePart = 1;
        this.$nextTick(() => {
         this.$refs[`refComp_1`].isEdit = true
         this.$refs[`refComp_1`].disabled = false
        })
      },
      handleChangeStatus({ status }) {
        this.status = status;
      },
      handleChangeTab(e) {
        // if (e > 0 && !this.status) {
        //   this.activePart = 0
        //   return this.$message.warning({
        //     content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">请先完成企业信息填写</span>,
        //     icon: (h) => <img src="/noPass.png" width="21" height="21" />,
        //   })
        // }
        this.$nextTick(() => {
          this.$refs[`refComp_${e}`] && this.$refs[`refComp_${e}`].handleInitData()
        })
      }
    },
  }
</script>

<style lang="less" scoped>
::v-deep .ant-tabs-nav .ant-tabs-tab {
  padding: 12px 0;
}
</style>
